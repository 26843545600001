import { useEffect, useRef, useState } from "react";
// react-router-dom components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import client from "ApiClient";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Switch,
  Typography,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SimpleReactValidator from "simple-react-validator";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { FormControlLabel } from "@material-ui/core";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

/* eslint-disable */
function Edit(props) {
  const [margin, setMargin] = useState(300);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const [openAlert, setOpenAlert] = useState(false);
  const closeAlert = () => setOpenAlert(false);

  const [errorSB, setErrorSB] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState(null);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userEnabled, setUserEnabled] = useState(false);
  const [userFree, setUserFree] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [stripeSubscriptionStatus, setStripeSubscriptionStatus] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const { openModal, closeModal } = props;
  const [user, setUser] = useState(props.user);
  const [settings, setSettings] = useState([]);
  const [, forceUpdate] = useState();
  const simpleValidator = useRef(new SimpleReactValidator());
  const [errorContent, setErrorContent] = useState("");
  const [errorTitle, setErrorTitle] = useState("");
  const [openErrorSB, setOpenErrorSB] = useState(false);
  const closeErrorSB = () => setOpenErrorSB(false);
  const [openTestSB, setOpenTestSB] = useState(false);
  const closeTestSB = () => setOpenTestSB(false);

  const [creationDate, setCreationDate] = useState("");

  const [setId, setSetId] = useState("");
  const [unsubscribeUrl, setUnsubscribeUrl] = useState("");
  const [unsubscribeHTML, setUnsubscribeHTML] = useState("");
  const [fromDomain, setFromDomain] = useState("");
  const [totalDailySends, setTotalDailySends] = useState(100);
  const [noEngagementLimitSends, setNoEngagementLimitSends] = useState("");
  const [initialHourOfDay, setInitialHourOfDay] = useState("");
  const [requestApproval, setRequestApproval] = useState(false);
  const [senders, setSenders] = useState(props.senders);
  const [selectedSender, setSelectedSenders] = useState(1);

  const [company, setCompany] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [coupons, setCoupons] = useState(props?.coupons);
  const [selectedCoupon, setSelectedCoupon] = useState("");

  document.addEventListener("focusin", (e) => {
    if (e.target.closest(".tox-tinymce-aux, .moxman-window, .tam-assetmanager-root") !== null) {
      e.stopImmediatePropagation();
    }
  });

  const options = {
    method: "POST",
    url: `emailCopy/Preview`,
    headers: {
      "content-type": "application/json",
    },
  };

  const clearVariables = () => {
    props.closeModal();
  };

  useEffect(() => {
    setIsLoading(true);
    setCoupons(props?.coupons);
    if (props.user != undefined);
    {
      setIsAdmin(props.user.user_type === 1);
      setName(props.user.user_name);
      setEmail(props.user.user_email);
      setUserEnabled(props.user.user_enabled);
      setEmailVerified(props.user.user_emailVerified);
      setStripeSubscriptionStatus(props.user.user_subscriptionStatus);
      setUser(props.user);
      setSenders(props.senders);
      setUserFree(props.user.user_free);
      setCompany(props.user.user_company);
      setCountry(props.user.user_country);
      setState(props.user.user_state);
      setCity(props.user.user_city);
      setAddress(props.user.user_address);
      setCreationDate(moment(props.user.user_creationDate));
      setSelectedCoupon(props.user.user_coupon == "" ? "Select a coupon" : props.user.user_coupon);
      setStripeCustomerId(props.user.user_stripeCustomerId);
    }
    setIsLoading(false);
  }, [props]);

  useEffect(() => {
    if (window.innerWidth < 1200) setMargin(0);
    else setMargin(300);
    //calculateRecipients();
  }, [window.innerWidth]);

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Validation failed"
      content="Please review al fields"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const renderAlert = (
    <MDSnackbar
      color="success"
      icon="success"
      title="Settings saved"
      content=""
      dateTime=""
      open={openAlert}
      onClose={closeAlert}
      close={closeAlert}
      bgWhite
    />
  );

  const handleSave = () => {
    // if (simpleValidator.current.allValid()) {
    setIsSaving(true);
    const options = {
      method: "PUT",
      url: `users`,
      headers: {
        "content-type": "application/json",
      },
    };

    const newUser = user;
    newUser.user_apiKey = user.user_apiKey;
    newUser.user_email = email;
    newUser.user_enabled = userEnabled;
    newUser.user_id = user.user_id;
    newUser.user_name = name;
    newUser.user_password = user.user_password;
    newUser.user_token = user.user_token;
    newUser.user_tokenExpires = user.user_tokenExpires;
    newUser.user_type = isAdmin ? 1 : 0;
    newUser.user_emailVerified = emailVerified;
    newUser.user_coupon = selectedCoupon == "Select a coupon" ? "" : selectedCoupon;
    newUser.user_subscriptionStatus = stripeSubscriptionStatus ? 1 : 0;
    newUser.user_creationDate = creationDate;
    newUser.user_doubleOptin = user.user_doubleOptin;
    newUser.user_sendpad_id = user.user_sendpad_id;
    newUser.user_sendpad_nextBillingDate = user.user_sendpad_nextBillingDate;
    newUser.user_sendpad_subscriptionFrequency = user.user_sendpad_subscriptionFrequency;
    newUser.user_sendpad_subscriptionPrice = user.user_sendpad_subscriptionPrice;
    newUser.user_free = userFree;
    newUser.user_company = company;
    newUser.user_country = country;
    newUser.user_state = state;
    newUser.user_city = city;
    newUser.user_address = address;

    options.data = JSON.stringify(newUser);

    client
      .request(options)
      .then((response) => {
        setIsLoading(false);
        setIsSaving(false);
        clearVariables();
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
    /* } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setErrorTitle("Warning");
      setErrorContent("Please make you sure that you has completed all fields");
      setOpenErrorSB(true);
    }*/
  };

  const unsubscribeUrlChange = (event) => {
    setUnsubscribeUrl(event.target.value);
  };

  const fromDomainChange = (event) => {
    setFromDomain(event.target.value);
  };

  const onChangeUnsubscribeHTML = (event) => {
    setUnsubscribeHTML(event.target.value);
  };

  const totalDailySendsChange = (event) => {
    setTotalDailySends(event.target.value);
  };

  const handleChangeName = (event) => {
    setName(event.target.value);
  };

  const handleChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const companyNameChange = (event) => {
    setCompany(event.target.value);
  };

  const companyCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const companyStateChange = (event) => {
    setState(event.target.value);
  };

  const companyCityChange = (event) => {
    setCity(event.target.value);
  };

  const companyAddressChange = (event) => {
    setAddress(event.target.value);
  };

  return (
    <Dialog
      open={openModal}
      onClose={(e, reason) => clearVariables(reason)}
      fullScreen
      disableEscapeKeyDown
      style={{ marginLeft: margin }}
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: "#F4F0F7FF",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDBox
        bgColor="primary"
        variant="gradient"
        borderRadius="lg"
        coloredShadow="primary"
        mx={2}
        mt={-1}
        p={2}
        textAlign="center"
      >
        <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Edit a user {name}
        </MDTypography>
      </MDBox>
      <DialogContent>
        <MDBox pt={4} pb={3} px={2}>
          <MDBox component="form" role="form">
            <Card style={{ padding: 10, backgroundColor: "white", marginBottom: 10 }}>
              <FormControlLabel
                style={{ marginLeft: 10, marginBottom: 10 }}
                value="end"
                labelPlacement="end"
                control={
                  <Switch checked={userEnabled} onChange={() => setUserEnabled(!userEnabled)} />
                }
                label={
                  <MDTypography variant="subtitle1">
                    User {userEnabled ? " can login" : "can't login"}
                  </MDTypography>
                }
              />
              <FormControlLabel
                style={{ marginLeft: 10, marginBottom: 10 }}
                value="end"
                labelPlacement="end"
                control={<Switch checked={isAdmin} onChange={() => setIsAdmin(!isAdmin)} />}
                label={<MDTypography variant="subtitle1">User is admin</MDTypography>}
              />
              <FormControlLabel
                style={{ marginLeft: 10, marginBottom: 10 }}
                value="end"
                labelPlacement="end"
                control={
                  <Switch
                    checked={emailVerified}
                    onChange={() => setEmailVerified(!emailVerified)}
                  />
                }
                label={
                  <MDTypography variant="subtitle1">
                    {emailVerified ? "Email account is verified" : "Email account is not verified"}
                  </MDTypography>
                }
              />
              <FormControlLabel
                style={{ marginLeft: 10, marginBottom: 10 }}
                value="end"
                labelPlacement="end"
                control={<Switch checked={userFree} onChange={() => setUserFree(!userFree)} />}
                label={
                  <MDTypography variant="subtitle1">
                    This is a free account with no subscription
                  </MDTypography>
                }
              />
              <MDBox sx={{ with: "40px" }}>
                <FormControlLabel
                  style={{ marginLeft: 10, marginBottom: 20 }}
                  labelPlacement="start"
                  control={
                    <Select
                      sx={{ width: "200px", height: "40px" }}
                      disabled={isLoading}
                      onChange={(e) => setSelectedCoupon(e.target.value)}
                      value={selectedCoupon}
                      inputProps={{
                        name: "select-coupon",
                        id: "select-coupon",
                      }}
                    >
                      <MenuItem key="" value="Select a coupon">
                        Select a coupon
                      </MenuItem>
                      {coupons.map((coupon) => (
                        <MenuItem key={coupon.cou_code} value={coupon.cou_code}>
                          {coupon.cou_code}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  label={<MDTypography variant="subtitle1">Coupon</MDTypography>}
                />
              </MDBox>
              {stripeCustomerId && (
                <MDBox display="flex" mb={2}>
                  <MDTypography variant="subtitle1">Stripe Customer ID:</MDTypography>
                  <MDTypography variant="subtitle1" color="text">
                    {stripeCustomerId}
                  </MDTypography>
                </MDBox>
              )}
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Name"
                  fullWidth
                  value={name}
                  onChange={handleChangeName}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Name", name, "required")}
                </MDBox>
              </MDBox>

              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Name"
                  fullWidth
                  value={name}
                  onChange={handleChangeName}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("Name", name, "required")}
                </MDBox>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Email"
                  fullWidth
                  value={email}
                  onChange={handleChangeEmail}
                  required
                />
                <MDBox color="red">
                  {simpleValidator.current.message("email", email, "required|email")}
                </MDBox>
              </MDBox>
              <MDBox mb={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Select the creation date for this user"
                    value={creationDate}
                    onChange={(newValue) => {
                      setCreationDate(newValue);
                    }}
                  />
                </LocalizationProvider>
              </MDBox>

              <Grid container spacing={5} p={2}>
                <Grid item xs={12} md={6}>
                  <Card style={{ height: "700px" }}>
                    <MDBox
                      mx={2}
                      mt={1}
                      py={3}
                      px={2}
                      variant="gradient"
                      bgColor="secondary"
                      borderRadius="lg"
                      color="white"
                      coloredShadow="secondary"
                    >
                      <Typography>Company</Typography>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Name"
                        fullWidth
                        value={company}
                        onChange={companyNameChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyName", company, "required")}
                      </MDBox>
                    </MDBox>

                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company State"
                        fullWidth
                        value={state}
                        onChange={companyStateChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyState", state, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Country"
                        fullWidth
                        value={country}
                        onChange={companyCountryChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyCountry", country, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company City"
                        fullWidth
                        value={city}
                        onChange={companyCityChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyCity", city, "required")}
                      </MDBox>
                    </MDBox>
                    <MDBox m={2}>
                      <MDInput
                        type="text"
                        label="Company Address"
                        fullWidth
                        value={address}
                        onChange={companyAddressChange}
                        required
                      />
                      <MDBox color="red">
                        {simpleValidator.current.message("companyAddress", address, "required")}
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </Card>
          </MDBox>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton
          onClick={clearVariables}
          disabled={isSaving || isLoading}
          variant="outlined"
          color="secondary"
        >
          Close
        </MDButton>
        <MDBox m={2}>
          <MDButton onClick={handleSave} disabled={isSaving} color="success" variant="gradient">
            Save
            {isSaving && (
              <CircularProgress
                size={24}
                style={{ marginLeft: 15, position: "relative", top: 4 }}
              />
            )}
          </MDButton>
        </MDBox>
      </DialogActions>

      <MDSnackbar
        color="success"
        icon="success"
        title="Success"
        content="Your test email has been sending successfully"
        dateTime={""}
        open={openTestSB}
        onClose={closeTestSB}
        close={closeTestSB}
        bgWhite
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={errorTitle}
        content={errorContent}
        dateTime={""}
        open={openErrorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
      />
    </Dialog>
  );
}
export default Edit;
